<template>
  <GroupDetails />
</template>

<script>
  import GroupDetails from '@/pages/groups/GroupDetails.vue';

  export default {
    components: {
      GroupDetails
    },
    name: 'GroupItem'
  };
</script>